import { arrangeCharacter } from "./data-default";
import dayjs from 'dayjs';
export const convertValueToText = (value) => {
    switch (value) {
      case 1:
        return 'とても低い';
      case 2:
        return '低い';
      case 3:
        return '普通';
      case 4:
        return '高い';
      case 5:
        return 'とても高い';
      default:
        return '';
    }
}
export const convertObjectValues = (values) => {
    const valueMap = {
      '1': 'とても低い',
      '2': '低い',
      '3': '普通',
      '4': '高い',
      '5': 'とても高い',
    };
  
    const convertedValues = {};
  
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        convertedValues[key] = valueMap[value];
      }
    }
  
    return convertedValues;
};
export function convertDataToNumber(data) {
    const convertedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        convertedData[key] = parseInt(data[key]);
      }
    }
    return convertedData;
}


export const sortObjectByOrder = (data) => {
  const sortedData = Object.fromEntries(
    Object.entries(data).sort((a, b) => arrangeCharacter.indexOf(a[0]) - arrangeCharacter.indexOf(b[0]))
  );
  return sortedData;
};
export const convertLableCharacter = (obj) => {
  const convertedObj = {};
  for (const key in obj) {
    switch (key) {
      case "奮起度":
        convertedObj["プレッシャー耐性"] = obj[key];
        break;
      case "誉め耐性":
        convertedObj["誉めによる意欲向上"] = obj[key];
        break;
      case "ストレス耐性":
        convertedObj["負荷の耐性"] = obj[key];
        break;
      default:
        convertedObj[key] = obj[key];
        break;
    }
  }
  return convertedObj;
};
export function formatDateYYYYMMDD(input) {
  return dayjs(input).format('YYYY/MM/DD');
}
export const valueCharacter = {
  1: 'とても低い',
  2: '低い',
  3: '普通',
  4: '高い',
  5: 'とても高い',
};

export function formatDate(input) {
  return dayjs(input).format('YYYY/MM/DD HH:mm:ss');
}

export function getLabelByValue(data, value) {
  for (let item of data) {
    if (item.value === value) {
      return item.label;
    }
  }
  return null;
}

export const sumTotalHalfWeekAll = (listTimeTotal, dataSource) => {
  let sumOfCourse = 0
  for (let i = 0; i < dataSource.length; i++) {
    const element1 = dataSource[i]?.TIME_UNIT[listTimeTotal[0]]?.unit ? dataSource[i]?.TIME_UNIT[listTimeTotal[0]]?.unit !== "T" ? dataSource[i]?.TIME_UNIT[listTimeTotal[0]]?.unit : 0 : 0;
    const element2 = dataSource[i]?.TIME_UNIT[listTimeTotal[1]]?.unit ? dataSource[i]?.TIME_UNIT[listTimeTotal[1]]?.unit !== "T" ? dataSource[i]?.TIME_UNIT[listTimeTotal[1]]?.unit : 0 : 0;
    const element3 = dataSource[i]?.TIME_UNIT[listTimeTotal[2]]?.unit ? dataSource[i]?.TIME_UNIT[listTimeTotal[2]]?.unit !== "T" ? dataSource[i]?.TIME_UNIT[listTimeTotal[2]]?.unit : 0 : 0;
    const element4 = dataSource[i]?.TIME_UNIT[listTimeTotal[3]]?.unit ? dataSource[i]?.TIME_UNIT[listTimeTotal[3]]?.unit !== "T" ? dataSource[i]?.TIME_UNIT[listTimeTotal[3]]?.unit : 0 : 0;
    sumOfCourse = Number(sumOfCourse) + Number(element1) + Number(element2) + Number(element3) + Number(element4)
  }
  return sumOfCourse
}

export const calculateTime = (currentStudent) => {
  const { startTime, endTime } = currentStudent;
  const dateStart = new Date(startTime)
  const dateEnd = new Date(endTime)
  const date = dateStart.getDate();
  const weekOfMonth = Math.ceil((date) / 7);
  let numberWeekInMonth = weekOfMonth === 5 ? 4 : weekOfMonth
  let numberWeekEnd = Math.ceil((dateEnd.getDate()) / 7) === 5 ? 4 : Math.ceil((dateEnd.getDate()) / 7)
  let numberWeeks = (((dateEnd.getMonth() - dateStart.getMonth()) + (dateEnd.getYear() - dateStart.getYear()) * 12) - 1) * 4 + (5 - numberWeekInMonth) + numberWeekEnd
  const listMonth = []
  const listMonthCompare = []
  const numberMont = []
  let numberWeek = 1
  let numberMonth = new Date(startTime).getMonth() + 1
  let numberYear = new Date(startTime).getFullYear()
  let numberWeekCompare = numberWeekInMonth
  let numberMonthCompare = new Date(startTime).getMonth() + 1
  let numberYearCompare = new Date(startTime).getFullYear()
  for (let i = 0; i < 52; i++) {
    if (numberWeek <= 4) {
      const time = numberMonth < 10 ? `${numberWeek}_0${numberMonth}_${numberYear}` : `${numberWeek}_${numberMonth}_${numberYear}`
      listMonth.push(time)
      
      if (numberMonth === 12 && numberWeek === 4) {
        numberMont.push(numberMonth)
        numberYear++
        numberMonth = 1
        numberWeek = 1
      } else if (numberWeek === 4) {
        numberMont.push(numberMonth)
        numberMonth++
        numberWeek = 1
      } else {
        numberWeek++
      }
    }
  }
  for (let i = 0; i < numberWeeks; i++) {
    if (numberWeekCompare <= 4) {
      const time = numberMonthCompare < 10 ? `${numberWeekCompare}_0${numberMonthCompare}_${numberYearCompare}` : `${numberWeekCompare}_${numberMonthCompare}_${numberYearCompare}`
      listMonthCompare.push(time)
      
      if (numberMonthCompare === 12 && numberWeekCompare === 4) {
        numberMont.push(numberMonthCompare)
        numberYearCompare++
        numberMonthCompare = 1
        numberWeekCompare = 1
      } else if (numberWeekCompare === 4) {
        numberMonthCompare++
        numberWeekCompare = 1
      } else {
        numberWeekCompare++
      }
    }
  }
  return { listMonth, numberMont, listMonthCompare }
}

export const calculateNumberOfPeriods = (dataSource) => {
  let sumTimeUnit = 0
    dataSource.map(item => {
      if (item['映・添'] === '映像') {
        const valueTimeUnit = Object.values(item['TIME_UNIT']).map(item => item.unit)
        sumTimeUnit +=  valueTimeUnit.reduce((accumulator, currentValue)=> accumulator + currentValue ,0 )
      }
      return sumTimeUnit;
    })
    return sumTimeUnit
}

export const  totalTimeOfCourse = (record) => {
  const valueTimeUnit = Object.values(record['TIME_UNIT']).map(item => item.unit)
  if (record['映・添'] === '映像') {
    return  valueTimeUnit.reduce((a,b)=>Number(a)+Number(b),0)
  }
  return 0
}

export const convertValueSubjectContent = (listSubject) => {
  let arrSubjectContent = [];

  for (const [key, value] of Object.entries(listSubject)) {
    const keyValue = Object.keys(value)
    if (key !== '社会' && key !== '理科') {
      arrSubjectContent.push({
        name: key,
        child: keyValue,
      });
    }
    else {
      const arrayChildValue = []
      for (const [key, valueChild] of Object.entries(value)) {
          arrayChildValue.push({
            name: key,
            child: valueChild,
          });
      }
      arrSubjectContent.push({
        name: key,
        child: arrayChildValue,
      });
    }
  }
  return arrSubjectContent
}

export const convertAutotickSubject = (listAutotick) => { 
  let arrAutotickContent = {};
  for (const [key, value] of Object.entries(listAutotick)) {
    const keyValue = Object.keys(value)
    if (key !== '社会' && key !== '理科') {
      arrAutotickContent[key] = keyValue
    }
    else {
      const arrayChildValue = {}
      let count = 0;
      for (const [key, valueChild] of Object.entries(value)) {
        if (count < 4) {
          arrayChildValue[key] = valueChild
          count++;
        }
      }
      arrAutotickContent[key] = arrayChildValue
    }
  }
  return arrAutotickContent
}

export const convertArrChildSubjectToObject = (data) => {
  const convertedObject = {};

  data.forEach(item => {
      convertedObject[item.name] = item.child;
  });
  return convertedObject
}


export function   flattenArrayFromObject(obj) {
  const result = [];
  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(value)) {
      result.push(...value);
    } else if (typeof value === 'object') {
      result.push(...flattenArrayFromObject(value));
    }
  }
  return result;
}

export const convertSubjectContentToKeyValueArray = (obj, getKeyScience, getKeyScociety) => {
  let converContent ={}
  obj.forEach(subject => {
    const { name, child } = subject;
    if (name === '理科' || name === '社会') {
      const subjectData = {};
      child.forEach(subSubject => {
        const { name: subName, child: subChild } = subSubject;
        if((getKeyScience && getKeyScience.includes(subName)) || (getKeyScociety && getKeyScociety.includes(subName))){
            subjectData[subName] = subChild;
        }      
      });
      converContent[name] = subjectData;
    } else {
      converContent[name] = child;
    }
  });
  return converContent
}

export const convertValueTickSubjectToArrEmpty = (data) => {
  const obj = {...data}
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = []
    }
    else if( typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = convertValueTickSubjectToArrEmpty(obj[key])
    }
    
  }
  return obj
}

export const convertDataContentBackFirst = (data , listContentFirst) => {
  const newData = {}
  
  for (const key in data) {
    if(Array.isArray(data[key])){
      const objChild = {}
      data[key].map(it => {
        objChild[it] = listContentFirst[key][it]
        return null
      })
        newData[key] = objChild
      }
    else {
      const objChild = {};
      for (const subKey in data[key]) {
          if (Array.isArray(data[key][subKey]) && data[key][subKey].length > 0) {
              objChild[subKey] = data[key][subKey];
          }
      }
       if (Object.keys(objChild).length > 0) {
            newData[key] = objChild;
        } else {
            delete newData[key];
        }
    }
  }
  return newData
}

export const uniqueValueObj = (obj) => {
  return Array.from(new Set(Object.values(obj).flat()))
}
export const mergeTwoArray = (arr1, arr2) => {
  return Array.from(new Set(arr1.concat(arr2)))
}

export const  sortBySubjectOrder = (data, orderArray, key) => {
  return data.sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      const orderA = orderArray.indexOf(valueA);
      const orderB = orderArray.indexOf(valueB);
      
      if (orderA === -1) return 1;
      if (orderB === -1) return -1;

      return orderA - orderB;
  });
}
