import { Notification } from 'Components';
import { BASE_API_URL, ERROR_API_MESSAGE } from 'Constants';
import i18next from 'i18next';
import streamSaver from 'streamsaver'
import { getLocalStorage, STORAGE } from 'Utils/storage';
i18next.loadNamespaces(['student']);
export const exportSchedule = async (myBody, studentName = "") => {
  const { generatedClassScheduleID, studentID } = myBody
  const url_request = `${BASE_API_URL}/api/ai-curriculum/multiple/detail/export_excel?studentID=${studentID}&curriculumID=${generatedClassScheduleID}`;
  // const { studentID } = myBody
  // const url_request = `${BASE_API_URL}/api/ai-curriculum/detail/export_excel?studentID=${studentID}`;
  const userToken = getLocalStorage(STORAGE.USER_TOKEN);
  let res 
  try {
    res = await fetch(url_request, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userToken}`
      },
    });
  } catch (error) {
    Notification.error(i18next.t("student:message.export_excel_failure"));
    return
  }
  if(res?.status !== 200){
    Notification.error(i18next.t("student:message.export_excel_failure"))
    return;
  }
  
  const fileStream = streamSaver.createWriteStream(`${studentID}-${studentName}.${"xlsx"}`);
  const writer = fileStream.getWriter();

  const reader = res.body.getReader();

  const pump = () =>
    reader.read().then(({ value, done }) => {
      if (done) writer.close();
      else {
        writer.write(value);
        return writer.ready.then(pump);
      }
    });

  await pump()
    .then(() => Notification.success(i18next.t("student:message.export_excel_success")))
    .catch((err) => Notification.error(ERROR_API_MESSAGE[err.message_code] || err));
};