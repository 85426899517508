import { Notification } from "Components";
import { BASE_API_URL, ERROR_API_MESSAGE } from "Constants";
import i18next from "i18next";
import streamSaver from "streamsaver";
import { getLocalStorage, STORAGE } from "Utils/storage";

i18next.loadNamespaces(["student"]);

export const exportExcelRaw = async (myBody) => {
  
  const { version, step } = myBody;

  const url_request = `${BASE_API_URL}/api/admin/data-import/download?version=${version}&step=${step}`;

  const adminToken = getLocalStorage(STORAGE.ADMIN_TOKEN);
  const res = await fetch(url_request, {
    method: "GET",
    // body: JSON.stringify(myBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    },
  });
  
  if (res.status !== 200) {
    Notification.error(i18next.t("student:message.export_excel_raw"));
    return;
  }
  let nameFile;
  switch (step) {
    case 1:
      nameFile = `講座関連情報_v${version}`;
      break;
    case 2:
      nameFile = `講座セット_v${version}`;
      break;
    case 3:
      nameFile = `科目別配点用_v${version}`;
      break;
    case 4:
      nameFile = `グループ関連情報_v${version}`;
      break;
    default:
      break;
  }
  const fileStream =  streamSaver.createWriteStream(`${nameFile}.${"xlsx"}`);
  const writer = fileStream.getWriter();

  const reader = res.body.getReader();
  
  const pump = async () => {
    const { value, done } = await reader.read()
    if (done) {
      await writer.close();
    }
    else {
      await writer.write(value)
      await writer.ready
      return pump()
    }
  }
  try {
    await pump()
  } catch (err) {
    err && Notification.error(ERROR_API_MESSAGE[err.message_code] || err)
  }
};
