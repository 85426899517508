
export const month = [
  { value: '1', label: '1月' },
  { value: '2', label: '2月' },
  { value: '3', label: '3月' },
  { value: '4', label: '4月' },
  { value: '5', label: '5月' },
  { value: '6', label: '6月' },
  { value: '7', label: '7月' },
  { value: '8', label: '8月' },
  { value: '9', label: '9月' },
  { value: '10', label: '10月' },
  { value: '11', label: '11月' },
  { value: '12', label: '12月' },
]

export const week = [
  { value: '1', label: '1週目' },
  { value: '2', label: '2週目' },
  { value: '3', label: '3週目' },
  { value: '4', label: '4週目' },
]

export const arrangeCharacter = ['プレッシャー耐性', '誉めによる意欲向上', '負荷の耐性', '上昇志向', '自立性'];

export const listSubject = ['英語', '数学', '理科', '社会', '国語', '小論文']
export const listTypeName = ['映像', '添削']
export const listTypeCourse = [ "本科","単科","実戦","添削"]
export const rowColors = [
  "bg-blue",
  "bg-red",
  "bg-green",
  "bg-yellow",
  "bg-orange",
  "bg-purple",
  "bg-pink",
  "bg-gray",
  "bg-teal",
  "bg-indigo",
  "bg-cyan",
  "bg-lime",
  "bg-amber",
  "bg-brown",
  "bg-emerald",
  "bg-indigo",
  "bg-fuchsia",
  "bg-lightBlue",
  "bg-violet",
  "bg-rose",
  "bg-sky",
  "bg-turquoise",
  "bg-greenYellow",
  "bg-navy",
  "bg-salmon",
  "bg-gold",
  "bg-lavender",
  "bg-magenta",
  "bg-wheat",
  "bg-olive",
];
export const Steps = {
  step1: 'ステップ①：講座関連情報をインポートする',
  step2: 'ステップ②：講座セットをインポートする',
  step3: ' ステップ③：科目別配点用をインポートする',
  step4: 'ステップ④：グループ関連情報インポート',
}
export const StepNumber = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
}
export const ImportStatus = {
  1: 'インポート',
  2: 'インポート済',
  3: '認証済',
  4: 'キャンセル済',
  5: '対応中',
  6: 'インポート',
}
export const ImportVersion = {
  1: 'インポート中',
  2: 'インポート済',
  3: '認証済',
  4: 'キャンセル済',
  5: '対応中',
}
export const ImportStatusClass = {
  1: 'IMPORT',
  2: 'IMPORTED',
  3: 'APPROVED',
  4: 'CANCELED',
  5: 'PROCESSING',
  6: 'IMPORT',
}
export const IMPORT_ERROR = "インポートエラー"
export const IMPORT_DECIDED = "未定"
export const TOOLTIP_ERROR_IMPORT = 'Excelファイル書式が正しくありません。確認してください。'
export const ImportVersionClass = {
  1: 'IN-PROGRESS',
  2: 'IMPORTED',
  3: 'APPROVED',
  4: 'CANCELED',
  5: 'PROCESSING',
  6: "IMPORT_ERROR"
}
export const optionsYesNo = [
  { label: "有", value: '1' },
  { label: "無", value: '0' },
];
export const textNoData = 'データがありません。'

export const RECALL_API_TIME  = 5000;
export const STOP_CALL_API_TIME  = 1000 * 60 * 5;

export const STATUS_ACTIVE = {
  0: '無効',
  1: '有効',
}

export  const dataStep = [
  { label: "ステップ① 講座関連情報", value: 1 },
  { label: "ステップ② 講座セット", value: 2 },
  { label: "ステップ③ 科目別配点用", value: 3 },
  { label: "ステップ④ グループ関連情報", value: 4 },
]

export const SUBJECT_NAME = {
  英語: 'english',
  国語: 'national_language',
  社会: 'society',
  数学: 'math',
  理科: 'science',
  英検専科: 'eiken_specialized_course',
  小論文: 'essay',
  情報: 'information'

}
export const COLORS_SUBJECT = {
  english: '#ffc0cb',
  national_language: '#ffd700',
  society: '#87cefa',
  math: '#98fb98',
  science: '#ee82ee',
  eiken_specialized_course: '#e9f9bc',
  essay: '#daa520 ',
  information: '#ee82ee'
  
};

export  const LIST_OPTION_LEARN = [
  { label: "毎週", value: 1 },
  { label: "2週間1回", value: 2 },
  { label: "3週間1回", value: 3 },
  { label: "4週間1回", value: 4 },
]

export  const LIST_OPTION_EDIT = [
  { label: "①この週のみ", value: 'only-week' },
  { label: "②すべての週", value: 'all-week' },
  { label: "③同じ科目すべて", value: 'all-course-subject' },
  { label: "④すべての講座", value: 'all-course' },
  { label: "⑤時限一括削除", value: 'delete-all' },
  { label: "⑥この講座の時限を削除", value: 'delete-one' },
]

export const VALUE_MAINTENANCE = {
  1: 'maintencance',
  0 : 'no_maintencance',
};
export const SHOW_NOTI = {
  1: 'show',
  0 : 'not_show',
};
export const VALUE_SWITCH = {
  true : 1,
  false : 0,
};
export const VALUE_ROLE = {
  1 : 'ADMIN',
  2: 'SUPER_ADMIN',
};
export const ALL = 'すべて';

export const NAME_AUTOTICK_SELECT = {
  SCIENCE : '理科',
  SOCIETY : '社会',
};

export const dataFake= [
  {
      "name": "英語",
      "child": [
          "文法",
          "構文",
          "読解",
          "英作",
          "リス",
          "発・アク",
          "英検"
      ]
  },
  {
      "name": "国語",
      "child": [
          "現代文",
          "古文",
          "漢文",
          "総合",
          "小論文"
      ]
  },
  {
      "name": "社会",
      "child": [
          "歴総",
          "世史",
          "日史",
          "地理",
          "公共",
          "倫理",
          "政経",
          "現社"
      ]
  },
  {
      "name": "数学",
      "child": [
          "IA",
          "IIB",
          "C",
          "III",
          "文系数学"
      ]
  },
  {
      "name": "理科",
      "child": [
          "物理基礎",
          "物理",
          "化学基礎",
          "化学",
          "生物基礎",
          "生物",
          "地学基礎",
          "地学",
          "理科"
      ]
  }
]
export const SUBJECT_ORDER = ['英語', '数学', '国語', '小論文', '理科'  ,'社会', '情報'];
